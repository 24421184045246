@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   normal;
$base-font-weight: normal;
$small-font-size:  small;
$base-line-height: normal;

$spacing-unit:     2em;

$text-color:       #111;
$background-color: #bfe9ff;
$link-color:       #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$hr-color:         #ffffff;
$hr-height:        0.5em;


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
