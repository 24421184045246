/**
 * 'hr'
 */
hr {
	color: $hr-color;
	background-color: $hr-color;
   padding-bottom: $hr-height;
   border: 0;
}

/**
 * Site header
 */
.site-header {
	padding: 0 $spacing_unit;
}

.site-title {
    font-size: x-large;
    font-weight: bold;

    padding-top: 0;
    padding-bottom: 0;
    
    float: right;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav-header {
    padding-top: calc($spacing_unit/2);
    padding-bottom: calc($spacing_unit/2);
    
    .page-link {
    	  display: block;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-bottom: 0.5em;
        }
    }
}

/**
 * Content header (sub menu)
 */

.content-header {
    padding: 0 $spacing_unit;
}

.content-nav-header {
    padding-top: calc($spacing_unit/2);
    padding-bottom: calc($spacing_unit/2);
    
    .page-link {

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 3em;
        }
    }
}


/**
 * Site footer
 */
 
.site-footer {
    padding: 0 $spacing_unit;
}

.site-nav-footer {
    padding-top: calc($spacing_unit/2);
    padding-bottom: calc($spacing_unit/2);

    .page-link {

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 3em;
        }
    }
}
 


/**
 * Page content
 */
.page-content {
    clear: both;
    padding: $spacing_unit;
}

.page-heading {
    font-size: large;
}
